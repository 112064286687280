import React, {useState, useRef, useEffect, CSSProperties, ReactNode, MutableRefObject, RefObject} from 'react';
import { linearInterpolation } from "simple-linear-interpolation";
import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import backgroundRainbowImage1 from './images/gradation_1.jpg'
// import backgroundRainbowImage2 from './images/gradation_2.jpg'
// import backgroundRainbowImage3 from './images/gradation_3.jpg'
// import backgroundRainbowImage4 from './images/gradation_4.jpg'

import topTitleLogoImage from './images/logo_pc+ipad.svg'
import topTitleLogoImageSp from './images/logo_sp.svg'
import dltLogoImage from './images/dlt-logo.svg'

// import masaPCVideo from './videos/masa-pc.mp4'
// import masaSPVideo from './videos/masa-sp.mp4'

// import staticVideo from './videos/static_1.mp4'
// import xypadVideo from './videos/xy_1.mp4'
// import shootingVideo from './videos/shooting_web.mp4'

import masaPCPosterImage from './images/posters/masa-pc-poster.jpg'
import masaSPPosterImage from './images/posters/masa-sp-poster.jpg'

import staticPosterImage from './images/posters/static-poster.jpg'
import xypadPosterImage from './images/posters/xypad-poster.jpg'
import shootingPosterImage from './images/posters/shooting-poster.jpg'

import masaImage from './images/masa.jpg'
import poneImage from './images/pone.jpg'

import soundOnImage from './images/sound-on.svg'
import soundOffImage from './images/sound-off.svg'

import prevSectionImage from './images/prev-section.png'
import nextSectionImage from './images/next-section.png'
import scrollUpImage from './images/scroll-up.png'
import scrollDownImage from './images/scroll-down.png'

import { Container } from '@mui/material';

const masaPCVideo = "https://firebasestorage.googleapis.com/v0/b/all-players-tool-lab.appspot.com/o/videos%2Fmasa-pc.mp4?alt=media&token=026dda47-f29e-4487-beae-fc147fdea406"
const masaSPVideo = "https://firebasestorage.googleapis.com/v0/b/all-players-tool-lab.appspot.com/o/videos%2Fmasa-sp.mp4?alt=media&token=ab06651e-37b3-48ae-a4cb-075cf1789372"

const staticVideo = 'https://firebasestorage.googleapis.com/v0/b/all-players-tool-lab.appspot.com/o/videos%2Fstatic_1.mp4?alt=media&token=a0333e5c-67d1-4db0-9254-1f47cff800bb'
const xypadVideo = 'https://firebasestorage.googleapis.com/v0/b/all-players-tool-lab.appspot.com/o/videos%2Fxy_1.mp4?alt=media&token=aa24cc6e-f42f-4689-b3ee-746d7fbd3764'
const shootingVideo = 'https://firebasestorage.googleapis.com/v0/b/all-players-tool-lab.appspot.com/o/videos%2Fshooting_web.mp4?alt=media&token=dd5ad4af-cf4d-49c3-8b47-89df5499c20f'


// ===========================================================
// UTILITIES

function useScrollEffect(callback: () => void) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    function onScroll(e: Event) {
      callbackRef.current()
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])
}

function loadImageAsync(src: string) {
  return new Promise(resolve => {
    const image = new Image()
    image.src = src
    image.onload = () => {
      resolve(image)
    }
  })
}

function useSetInterval(callback: () => void, msec: number) {
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    function onUpdate(e: Event) {
      callbackRef.current()
    }

    const timer = setInterval(onUpdate, msec)

    return () => {
      clearInterval(timer)
    }
  }, [])
}

function useInScreen(ref: RefObject<HTMLElement>) {
  const size = useWindowSize()
  const [inScreen, setInScreen] = useState(false);

  useScrollEffect(() => {
    const dom = ref.current
    if (dom) {
      const rect = dom.getBoundingClientRect()
      const inScreen = rect.y < size.height && rect.y + rect.height > 0
      setInScreen(inScreen)
    }
  })

  return inScreen
}

function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const onResize = () => {
      const newSize = {
        width: window.innerWidth,
        height: size.height, // don't change height
      }

      setSize(newSize)
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return size
}

function interp(options: {small: number, large: number, value: number}) {
  const x0 = screenBreakPoints.small
  const x1 = screenBreakPoints.large
  const y0 = options.small
  const y1 = options.large
  const x = options.value
  const points = [{ x: x0, y: y0}, { x: x1, y: y1 }];
  const calculate = linearInterpolation(points);

  const xClamped = Math.min(Math.max(x, x0), x1)
  return calculate({x: xClamped})
}

function useResponsive() {
  const size = useWindowSize()
  const responsive = (small: number, large: number) => {
    return interp({
      small, large, value: size.width,
    })
  }
  return responsive
}

type ScreenType = "small" | "middle" | "large"
const screenBreakPoints = {
  extraSmall: 0,
  small: 375,
  middle: 600,
  large: 1400
}

function useScreenType() {
  const size = useWindowSize();

  let screenType: ScreenType = "small"
  const screenTypes: ScreenType[] = [ "small", "middle", "large", ]

  screenTypes.forEach(t => {
    if (screenBreakPoints[t] <= size.width) {
      screenType = t
    }
  });

  return screenType
}

export function YouTube({id, options = {}}: {id: string, options?: {[key: string]: any}}) {

  const optionsWithPlaylist: {[key: string]: string} = {
    playlist: id,
    ...options
  }

  const query = Object.keys(optionsWithPlaylist).map(key => [key, String(optionsWithPlaylist[key])].join('=')).join('&')

  const containerStyle: CSSProperties = {
    width: '100%',
    aspectRatio: "16/9",
  }

  const iframeStyle: CSSProperties = {
    width: "100%",
    height: "100%",
  }

  return <div style={containerStyle}>
    <iframe 
      width="560" height="315" 
      src={`https://www.youtube.com/embed/${id}?${query}`} 
      frameBorder={0} 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen={true}
      style={iframeStyle}
      ></iframe>
  </div>
}

function useGlobalPaddingLeft() {
  const size = useWindowSize()

  return interp({
    small: 16,
    large: 90,
    value: size.width,
  })
}

function VStack(props: {children?: ReactNode, style?: CSSProperties}) {
  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      ...props.style,
    }}
  >{props.children}</div>
}

function HStack(props: {children?: ReactNode, style?: CSSProperties}) {
  return <div
    style={{
      display: 'flex',
      ...props.style,
    }}
  >{props.children}</div>
}

function HSpacer(props: {width: number}) {
  return <div style={{width: props.width, height: '100%'}} ></div>
}

function VSpacer(props: {height: number, style?: CSSProperties}) {
  return <div style={{width: '100%', height: props.height, ...(props.style ?? {})}} ></div>
}

function HoverButton(props: {children?: ReactNode}) {
  const [isHover, setIsHover] = useState(false)

  const style: CSSProperties = {
    opacity: isHover ? 1.0 : 0.4,
    cursor: 'pointer',
  }

  function onMouseEnter() {
    setIsHover(true)
  }

  function onMouseLeave() {
    setIsHover(false)
  }

  return <div 
    style={style} 
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    >
    {props.children}
  </div>

}

// ==========================================================
// CONTENTS


function ScrollButtons() {
  const size = useWindowSize()
  const screenType = useScreenType()

  if (screenType === 'small') {
    return null
  }

  function prevSection() {
    let prevSection: HTMLDivElement|undefined = undefined

    for (let section of SectionManager.shared.sections) {
      if (section.current) {
        const rect = section.current.getBoundingClientRect()
        if (rect.y < -10) {
          prevSection = section.current
        }
      }
    }

    if (prevSection) {
      const y = window.scrollY;
      const rect = prevSection.getBoundingClientRect()

      window.scrollTo({
        top: y + rect.y,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  function nextSection() {
    for (let section of SectionManager.shared.sections) {
      if (section.current) {
        const rect = section.current.getBoundingClientRect()
        if (rect.y > 10) {
          const y = window.scrollY;

          window.scrollTo({
            top: y + rect.y,
            left: 0,
            behavior: 'smooth',
          })

          break
        }
      }
    }
  }

  function scrollUp() {
    const y = window.scrollY;

    window.scrollTo({
      top: y - size.height * 0.8,
      left: 0,
      behavior: 'smooth',
    })
  }

  function scrollDown() {
    const y = window.scrollY;

    window.scrollTo({
      top: y + size.height * 0.8,
      left: 0,
      behavior: 'smooth',
    })
  }

  const style: CSSProperties = {
    position: 'fixed',
    top: 40,
    right: 13,
    zIndex: 10,
  }

  const imageStyle: CSSProperties = {
    width: 74,
    height: 72,
  }

  return <VStack style={style}>
    <HoverButton>
      <img style={imageStyle} src={prevSectionImage} onClick={prevSection} />
    </HoverButton>
    {/* <VSpacer height={12} /> */}
    <HoverButton>
      <img style={imageStyle} src={scrollUpImage} onClick={scrollUp}/>
    </HoverButton>
    {/* <VSpacer height={12} /> */}
    <HoverButton>
      <img style={imageStyle} src={scrollDownImage} onClick={scrollDown} />
    </HoverButton>
    {/* <VSpacer height={12} /> */}
    <HoverButton>
      <img style={imageStyle} src={nextSectionImage} onClick={nextSection} />
    </HoverButton>
  </VStack>
}

function RainbowBackground(props: {children?: ReactNode}) {
  const [counter, setCounter] = useState(0)
  const shouldShowFront = counter % 2 === 0
  const [fadeHasEnded, setFadeHasEnded] = useState(true)

  const fadeDuration = 1000
  const imageDuration = 5000
  const transitionLength = 10

  const backgroundImages = [
    backgroundRainbowImage1, // 複数枚画像を読むと、低速インターネット時にグリッチが起きる
    // backgroundRainbowImage2,
    // backgroundRainbowImage3,
    // backgroundRainbowImage4,
  ]

  const [loadedBackgroundImages, setLoadedBackgroundImages] = useState<string[]>([])

  useEffect(() => {
    const loadedBackgroundImages: string[] = []

    const begin = Number(new Date())

    async function preloadImages() {
      for (let url of backgroundImages) {
        await loadImageAsync(url)

        // console.log('loaded', url)
        // console.log(Number(new Date()) - begin)
        loadedBackgroundImages.push(url);
        setLoadedBackgroundImages(loadedBackgroundImages)
      }


      // backgroundImages.forEach(url => {
      //   const image = new Image()
      //   image.src = url
      //   image.onload = () => {
      //     console.log('loaded', url)
      //     loadedBackgroundImages.push(url);
      //     console.log(Number(new Date()) - begin)
      //     setLoadedBackgroundImages(loadedBackgroundImages)
      //   }
      // })
    }

    preloadImages()

    setCounter(counter + 1)

    return () => {}
  }, [])

  useSetInterval(() => {
    setCounter(counter + 1)
    setFadeHasEnded(false)
    // console.log('interval')
    setTimeout(() => {
      // console.log('set fade has ended')
      setFadeHasEnded(true)
    }, fadeDuration)
  }, imageDuration + fadeDuration)

  const style: CSSProperties = {
    position: 'relative', 
    overflow: 'hidden',
    backgroundColor: 'black',
  }

  const backgroundImage = loadedBackgroundImages[counter % loadedBackgroundImages.length]
  const nextBackgroundImage = loadedBackgroundImages[(counter + 1) % loadedBackgroundImages.length]

  const imagesStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    objectFit: 'cover',
    top: 0, left: 0,
    transitionProperty: 'opacity, background-image, transform',
    // transitionDuration: `${fadeDuration}ms, ${imageDuration + fadeDuration}ms`,
    transitionTimingFunction: 'linear, linear, linear',
  }

  const backImageStyle: CSSProperties = {
    zIndex: 0,
    transform: `translateY(${
      !shouldShowFront || !fadeHasEnded ? `-${transitionLength}%` : `${transitionLength}%`
    }) scale(140%)`,
    transitionDuration: `${fadeDuration}ms, ${fadeDuration}ms, ${shouldShowFront && fadeHasEnded ? 0 : imageDuration + fadeDuration * 2}ms`,
  }

  const frontImageStyle: CSSProperties = {
    zIndex: 1,
    opacity: shouldShowFront ? 1 : 0,
    transform: `translateY(${
      shouldShowFront || !fadeHasEnded ? `-${transitionLength}%` : `${transitionLength}%`
    }) scale(140%)`,
    transitionDuration: `${fadeDuration}ms, ${fadeDuration}ms, ${!shouldShowFront && fadeHasEnded ? 0 : imageDuration + fadeDuration * 2}ms`,
  }

  const containerStyle: CSSProperties = {
    position: 'relative',
    zIndex: 2,
  }

  return <div style={style}>
    <img src={shouldShowFront ? backgroundImage : nextBackgroundImage} style={{...imagesStyle, ...backImageStyle}}></img>
    <img src={shouldShowFront ? nextBackgroundImage : backgroundImage} style={{...imagesStyle, ...frontImageStyle}}></img>
    <div style={containerStyle}>
      {props.children}
    </div>
  </div>
}


function TopTitle() {
  const size = useWindowSize()
  const screenType = useScreenType()

  const style: CSSProperties = {
    width: '100%',
    height: size.height,
    justifyContent: "flex-end",
    alignItems: 'center',
  }

  return <VStack style={style}>
    <img 
      src={
        screenType === 'small' ? topTitleLogoImageSp : topTitleLogoImage} 
      alt="ALL PLAYERS TOOL LAB."
      style={{
      width: '97%',
      height: 'auto',
      marginBottom: '1.5%',
    }}></img>

  </VStack>;
}


function Statement() {
  const paddingLeft = useGlobalPaddingLeft()
  const size = useWindowSize()
  const screenType = useScreenType()

  const style: CSSProperties = {
    paddingLeft,
  }

  const titleStyle: CSSProperties = {
    fontSize: interp({
      small: 42,
      large: 100,
      value: size.width,
    }),
    color: 'white',
    fontWeight: 'bold',
    marginBottom: 40,
    lineHeight: screenType === "small" ? '1.0em' : '1.4em',
  }

  const statementStyle: CSSProperties = {
    // fontSize: 60,
    fontSize: interp({
      small: 30,
      large: 60,
      value: size.width,
    }),
    fontWeight: 'bold',
    color: 'white',
    opacity: 0.7,
    lineHeight: '1.4em',
  }

  return <div style={style}>
    <VSpacer height={195}></VSpacer>
    <div style={titleStyle}>
      { screenType === "small" ?
      <>
        <p> Creativity yet</p><p>to be seen </p>
        <p> for the future yet</p><p> to be seen. </p>
      </>
      :
      <>
        <p> Creativity yet to be seen </p>
        <p> for the future yet to be seen. </p>
      </>
      }
    </div>
    <div style={statementStyle}>
      <p> ‘All Players Tool Lab.’ is a lab where human physicality is researched  </p>
      <p> from undiscovered perspectives, and where new creative innovations happen.  </p>
    </div>
    <VSpacer height={195}></VSpacer>
  </div>;
}

function LivePerformanceWithEyes() {
  const padding = useGlobalPaddingLeft()
  const size = useWindowSize()
  const screenType = useScreenType()
  const responsive = useResponsive()

  const ref = useRef<HTMLDivElement>(null)
  const inScreen = useInScreen(ref)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(function(){
    const video = videoRef.current
    if (video) {
      if (inScreen) {
        video.play()
      } else {
        video.pause()
      }
    }
  }, [inScreen])

  const style: CSSProperties = {
    position: 'relative',
    paddingLeft: padding,
    paddingRight: padding,
    color: 'white',
    // backgroundImage: `url(${masaBackgroundImage})`,
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
  }

  const numberStyle: CSSProperties = {
    fontSize: 35,
    fontWeight: 'bold',
  }

  const titleStyle: CSSProperties = {
    fontSize: responsive(24, 120),
    fontWeight: 'bold',
  }

  const textStyle: CSSProperties = {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: screenType === "small" ? '1.4em' : '2em',
    maxWidth: 720,
    opacity: 0.8,
  }

  const playerContainerStyle: CSSProperties = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: 8,
    borderRadius: 10,
    width: responsive(300, 370),
    marginRight: 8,
    marginBottom: 16,
  }

  const playerPhotoStyle: CSSProperties = {
    borderRadius: 6, 
    width: responsive(130, 160),
    marginRight: 8,
  }

  const playerNameStyle: CSSProperties = {
    fontSize: responsive(25, 30),
    fontWeight: 'bold',
    color: 'black',
  }

  const playerJobStyle: CSSProperties = {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'black',
    opacity: 0.8,
  }

  const videoPlayerStyle: CSSProperties = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    left: 0,
    top: 0,
    zIndex: -1,
    backgroundColor: 'black',
  }

  return <div style={style} ref={ref}>
    <video 
      src={ screenType === 'small' ? masaSPVideo : masaPCVideo } 
      style={videoPlayerStyle} autoPlay={false} muted={true} loop={true} playsInline={true}
      poster={ screenType === 'small' ? masaSPPosterImage : masaPCPosterImage } 
      ref={videoRef}
      ></video>

    <VSpacer height={138}></VSpacer>
    <div style={numberStyle}>#001</div>
    <VSpacer height={118}></VSpacer>
    <div style={titleStyle}>LIVE PERFORMANCE WITH EYES</div>
    {/* <VSpacer height={99}></VSpacer> */}
    <VSpacer height={ responsive(20, 99) }></VSpacer>
    <div style={textStyle}>
      <p> Based on a collaborative research, ALS musicians MASA and PONE created a live performance tool that can be operated with their eyes. </p>
    </div>
    <VSpacer height={44}></VSpacer>
    <div style={{display: screenType === "small" ? 'block' : 'flex'}}>
      <HStack style={playerContainerStyle}>
        <img src={masaImage} style={playerPhotoStyle}></img>
        <div>
          <div style={playerNameStyle}>
            MASA
          </div>
          <div style={playerJobStyle}>
            EYE VDJ &amp; <br /> Communication Creator <br /> WITH ALS 
          </div>
        </div>
      </HStack>

      <HStack style={playerContainerStyle}>
        <img src={poneImage} style={playerPhotoStyle}></img>
        <div>
          <div style={playerNameStyle}>
            PONE
          </div>
          <div style={playerJobStyle}>
            Composer
          </div>
        </div>
      </HStack>
    </div>
    <VSpacer height={responsive(120, 270)}></VSpacer>

  </div>;
}


function BlackBackground(props: {children?: ReactNode}) {
  return <div style={{position: 'relative', backgroundColor: 'black'}}>{props.children}</div>
}

interface SupportTool {
  name: string,
  link: string,
}


function VideoSoundSwitch(props: {
  muted: boolean,
  setMuted: (muted: boolean) => void
}) {
  const responsive = useResponsive()

  function onClick() {
    const newValue = !props.muted
    props.setMuted(newValue)
  }

  const style: CSSProperties = {
    position: 'absolute',
    right: responsive(8, 32),
    bottom: responsive(8, 32),
    cursor: 'pointer',
    width: responsive(44, 64),
    height: 'auto',
  }

  return <img 
    src={props.muted ? soundOffImage : soundOnImage} 
    onClick={onClick}
    style={style}></img>
}


function Tool(props: {
  title: string,
  subTitle: ReactNode,
  description: string,
  supportedTools: SupportTool[],
  captureImage?: string,
  captureVideo?: string,
  captureVideoPoster?: string,
  youtubeVideoId?: string,
}) {
  const {
    title,
    subTitle,
    description,
    supportedTools,
    captureImage,
    captureVideo,
    youtubeVideoId,
    captureVideoPoster,
  } = props

  const size = useWindowSize()
  const responsive = useResponsive()
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoIsInScreen = useInScreen(videoRef)
  const [muted, setMuted] = useState(true)

  useEffect(function(){
    const video = videoRef.current;
    if (video)  {
      if (videoIsInScreen) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, [videoIsInScreen])

  const style: CSSProperties = {
    backgroundColor: 'white',
  }

  const titleStyle: CSSProperties = {
    // fontSize: 120,
    // fontSize: interp({
    //   large: 120,
    //   small: 50,
    //   value: size.width,
    // }),
    fontSize: responsive(45, 120),
    fontWeight: 'bold',
  }

  const subTitleStyle: CSSProperties = {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
    opacity: 0.8,
  }

  const supportedToolsTitleStyle: CSSProperties = {
    fontSize: 13,
    fontWeight: 'bold',
    color: 'black',
    opacity: 0.8,
  }

  const supportedToolsStyle: CSSProperties = {
    fontSize: responsive(16, 24),
    fontWeight: 'bold',
    color: 'black',
    opacity: 0.8,
  }

  const videoPlayerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }

  return <div style={style}>
    <VSpacer height={responsive(45, 187)} />
    <Container>
      <div style={titleStyle}>{title}</div>
      <VSpacer height={10}></VSpacer>
      <div style={subTitleStyle}>{subTitle}</div>
    </Container>
    <VSpacer height={responsive(65, 95)}></VSpacer>
    <BlackBackground>
      { captureImage ?
        <Container>
          <img src={captureImage} style={{width: '100%'}} />
        </Container>
      : null }
      { youtubeVideoId ?
        <YouTube id={youtubeVideoId} options={{autoplay: 1, mute: 1, playsinline: 1, loop: 1, controls: 0, modestbranding: 0}} ></YouTube>
      : null }
      { captureVideo ?
        <>
          <video 
            ref={videoRef}
            src={ captureVideo }
            style={videoPlayerStyle} 
            autoPlay={false} 
            poster={captureVideoPoster}
            muted={muted} loop={true} playsInline={true}></video>
          <VideoSoundSwitch muted={muted} setMuted={setMuted} />
        </>
      : null }
        <div>

        </div>
    </BlackBackground>
    <VSpacer height={46}></VSpacer>
    <Container>
      <div style={subTitleStyle}>
        {description}
      </div>
      <VSpacer height={34}></VSpacer>

      <HStack>
        <div>
          <div style={supportedToolsTitleStyle}>
            Supported tools
          </div>
          <VSpacer height={5}></VSpacer>
          { supportedTools.map((tool, i) => 
          <React.Fragment key={i}>
            { i > 0 ? " • " : null }
            <a href={tool.link} style={{...supportedToolsStyle, fontWeight: 'bold'}}>{tool.name}</a>
          </React.Fragment>
            ) }
        </div>
      </HStack>
    </Container>
    {/* <VSpacer height={187}></VSpacer> */}
  </div>;
}

function EyeXYPad() {
  const screenType = useScreenType()
  const br = screenType === 'small' ? <br></br> : null
  return <Tool
    title="EYE XY PAD"
    // subTitle="Performance device played with eye movements."
    captureVideo={xypadVideo}
    captureVideoPoster={xypadPosterImage}
    subTitle={<>Performance device played {br} with eye movements.</>}
    // captureImage={eyeXYPadImage}
    description='An innovative UI that enables real-time effects manipulation and synthesizer performance.'
    supportedTools={[
      {name: 'OriHime Eye', link: "https://orihime.orylab.com/eye/"},
    ]}
  ></Tool>
}

function EyeMidiPad() {
  return <Tool
    title="EYE MIDI PAD"
    subTitle="Efficient track operation UI."
    // captureImage={eyeMIDIPadImage}
    // youtubeVideoId='hNfGjw_Vt0M'
    captureVideo={staticVideo}
    captureVideoPoster={staticPosterImage}
    description='UI that allows efficient track operation even with tools that are difficult to operate in real time, such as Windows Control'
    supportedTools={[
      {name: 'OriHime Eye', link: "https://orihime.orylab.com/eye/"}, 
      {name: 'Windows Control', link: "https://www.tobiidynavox.com/products/windows-control"},
    ]}
  ></Tool>
}

function ShootingPad() {
  return <Tool
    title="SHOOTING PAD"
    subTitle="Delay-enabled remote performance device."
    // captureImage={shootingPadImage}
    captureVideo={shootingVideo}
    captureVideoPoster={shootingPosterImage}
    description='UI that allows players with disabilities to perform remotely, even when there is network delay.'
    supportedTools={[
      {name: 'Gaze Point', link: "https://www.tobiidynavox.com/products/gaze-point"},
    ]}
  ></Tool>
}

function ComingSoon() {
  const paddingLeft = useGlobalPaddingLeft()
  const size = useWindowSize()
  const responsive = useResponsive()

  const style: CSSProperties = {
    paddingLeft,
    color: 'white',
    backgroundColor: 'black',
  }

  const numberStyle: CSSProperties = {
    // fontSize: 35,
    fontSize: responsive(25, 35),
    fontWeight: 'bold',
  }

  const titleStyle: CSSProperties = {
    fontSize: responsive(42, 120),
    fontWeight: 'bold',
  }

  const textStyle: CSSProperties = {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '1.4em',
    opacity: 0.8,
  }

  return <div style={style}>
    {/* <VSpacer height={138}></VSpacer> */}
    <VSpacer height={
      interp({
        small: 54,
        large: 138,
        value: size.width,
      })
    }></VSpacer>
    <div style={numberStyle}>#002</div>
    <VSpacer height={responsive(49, 118)}></VSpacer>
    <div style={titleStyle}>Coming Soon</div>
    <VSpacer height={16}></VSpacer>
    <div style={textStyle}>
      <p>
        All Players Tool Lab. will continue to develop tools that explore human physicality, expanding the potential of our creativity.
      </p>
    </div>
    <VSpacer height={155}></VSpacer>
  </div>;
}

function Footer() {
  const paddingLeft = useGlobalPaddingLeft()
  const responsive = useResponsive()

  const style: CSSProperties = {
    paddingLeft,
    color: 'white',
    fontWeight: 'bold',
  }

  const textStyle: CSSProperties = {
    fontSize: 20,
  }

  const contactButtonStyle: CSSProperties = {
    fontWeight: 'normal',
    textAlign: 'center',
    backgroundColor: 'white',
    color: '#EC4E27',
    padding: '10px 50px',
    borderRadius: 1000,
    fontSize: responsive(32, 50),
    textDecoration: 'none',
  }

  const logoStyle: CSSProperties = {
    width: 303,
    height: 'auto',
  }

  const copyrightStyle: CSSProperties  = {
    paddingRight: paddingLeft,
    textAlign: 'right',
    fontSize: 15,
    fontWeight: 500,
  }

  return <RainbowBackground>
    <div style={style}>
      <VSpacer height={responsive(55, 94)}></VSpacer>
      <div style={textStyle}>
        If you’d like to use these tools, please kindly contact us here:
      </div>
      <VSpacer height={50}></VSpacer>

      <div>
        <a 
          style={contactButtonStyle}
          href="mailto:d.labtokyo@gmail.com"
        >
          Contact
        </a>
      </div>

      <VSpacer height={95} ></VSpacer>

      {/* <img style={logoStyle} src={dltLogoImage} alt="dentsu lab tokyo"></img> */}

      <VSpacer height={114}></VSpacer>
      {/* <div style={copyrightStyle}>© Dentsu Lab Tokyo All Rights Reserved.
      </div> */}
      {/* <VSpacer height={30}></VSpacer> */}
      {/* <VSpacer height={30}></VSpacer> */}
      <VSpacer height={240}></VSpacer>
    </div>
  </RainbowBackground>
}

class SectionManager {
  sections: RefObject<HTMLDivElement>[] = []
  static shared = new SectionManager()
}

function Section(props: {children?: ReactNode}) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    SectionManager.shared.sections.push(ref)

    return () => {
      SectionManager.shared.sections = SectionManager.shared.sections.filter(e => e !== ref)
    }
  }, [])

  return <div ref={ref}> {props.children} </div>
}

function App() {
  const size = useWindowSize()

  function fixScroll() {
    const y = window.scrollY;
    if (y < size.height) {
      window.scrollTo({top: 0, left: 0})
    }
  }

  // 勝手にスクロールする対策
  useEffect(() => {

    setTimeout(fixScroll, 200)

    return () => {}
  }, [])

  const style: CSSProperties = {
    width: size.width,
    overflow: 'hidden',
  }

  return (
    <div style={style}>
      <ScrollButtons />
      <RainbowBackground>
        <Section />
        <TopTitle></TopTitle>
        <Section />
        <Statement></Statement>
      </RainbowBackground>
      <Section />
      <LivePerformanceWithEyes></LivePerformanceWithEyes>
      <Section />
      <EyeXYPad></EyeXYPad>
      <Section />
      <EyeMidiPad></EyeMidiPad>
      <Section />
      <ShootingPad></ShootingPad>
      <Section />
      <VSpacer height={124} style={{backgroundColor: 'white'}}></VSpacer>
      <Section />
      <ComingSoon></ComingSoon>
      <Section />
      <Footer></Footer>
    </div>
  );
}

export default App;
